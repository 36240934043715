// // Fonts
// /* lato-100 - latin */
// @font-face {
//     font-display: swap;
//     font-family: "Lato";
//     font-style: normal;
//     font-weight: 100;
//     src: url("/fonts/lato/lato-v16-latin-100.eot"); /* IE9 Compat Modes */
//     src: local("Lato Hairline"), local("Lato-Hairline"),
//         url("/fonts/lato/lato-v16-latin-100.eot?#iefix")
//             format("embedded-opentype"),
//         /* IE6-IE8 */ url("/fonts/lato/lato-v16-latin-100.woff2")
//             format("woff2"),
//         /* Super Modern Browsers */ url("/fonts/lato/lato-v16-latin-100.woff")
//             format("woff"),
//         /* Modern Browsers */ url("/fonts/lato/lato-v16-latin-100.ttf")
//             format("truetype"),
//         /* Safari, Android, iOS */
//             url("/fonts/lato/lato-v16-latin-100.svg#Lato") format("svg"); /* Legacy iOS */
// }

// /* lato-100italic - latin */
// @font-face {
//     font-display: swap;
//     font-family: "Lato";
//     font-style: italic;
//     font-weight: 100;
//     src: url("/fonts/lato/lato-v16-latin-100italic.eot"); /* IE9 Compat Modes */
//     src: local("Lato Hairline Italic"), local("Lato-HairlineItalic"),
//         url("/fonts/lato/lato-v16-latin-100italic.eot?#iefix")
//             format("embedded-opentype"),
//         /* IE6-IE8 */ url("/fonts/lato/lato-v16-latin-100italic.woff2")
//             format("woff2"),
//         /* Super Modern Browsers */
//             url("/fonts/lato/lato-v16-latin-100italic.woff") format("woff"),
//         /* Modern Browsers */ url("/fonts/lato/lato-v16-latin-100italic.ttf")
//             format("truetype"),
//         /* Safari, Android, iOS */
//             url("/fonts/lato/lato-v16-latin-100italic.svg#Lato") format("svg"); /* Legacy iOS */
// }
// /* lato-300italic - latin */
// @font-face {
//     font-display: swap;
//     font-family: "Lato";
//     font-style: italic;
//     font-weight: 300;
//     src: url("/fonts/lato/lato-v16-latin-300italic.eot"); /* IE9 Compat Modes */
//     src: local("Lato Light Italic"), local("Lato-LightItalic"),
//         url("/fonts/lato/lato-v16-latin-300italic.eot?#iefix")
//             format("embedded-opentype"),
//         /* IE6-IE8 */ url("/fonts/lato/lato-v16-latin-300italic.woff2")
//             format("woff2"),
//         /* Super Modern Browsers */
//             url("/fonts/lato/lato-v16-latin-300italic.woff") format("woff"),
//         /* Modern Browsers */ url("/fonts/lato/lato-v16-latin-300italic.ttf")
//             format("truetype"),
//         /* Safari, Android, iOS */
//             url("/fonts/lato/lato-v16-latin-300italic.svg#Lato") format("svg"); /* Legacy iOS */
// }
// /* lato-300 - latin */
// @font-face {
//     font-display: swap;
//     font-family: "Lato";
//     font-style: normal;
//     font-weight: 300;
//     src: url("/fonts/lato/lato-v16-latin-300.eot"); /* IE9 Compat Modes */
//     src: local("Lato Light"), local("Lato-Light"),
//         url("/fonts/lato/lato-v16-latin-300.eot?#iefix")
//             format("embedded-opentype"),
//         /* IE6-IE8 */ url("/fonts/lato/lato-v16-latin-300.woff2")
//             format("woff2"),
//         /* Super Modern Browsers */ url("/fonts/lato/lato-v16-latin-300.woff")
//             format("woff"),
//         /* Modern Browsers */ url("/fonts/lato/lato-v16-latin-300.ttf")
//             format("truetype"),
//         /* Safari, Android, iOS */
//             url("/fonts/lato/lato-v16-latin-300.svg#Lato") format("svg"); /* Legacy iOS */
// }
// /* lato-regular - latin */
// @font-face {
//     font-display: swap;
//     font-family: "Lato";
//     font-style: normal;
//     font-weight: 400;
//     src: url("/fonts/lato/lato-v16-latin-regular.eot"); /* IE9 Compat Modes */
//     src: local("Lato Regular"), local("Lato-Regular"),
//         url("/fonts/lato/lato-v16-latin-regular.eot?#iefix")
//             format("embedded-opentype"),
//         /* IE6-IE8 */ url("/fonts/lato/lato-v16-latin-regular.woff2")
//             format("woff2"),
//         /* Super Modern Browsers */
//             url("/fonts/lato/lato-v16-latin-regular.woff") format("woff"),
//         /* Modern Browsers */ url("/fonts/lato/lato-v16-latin-regular.ttf")
//             format("truetype"),
//         /* Safari, Android, iOS */
//             url("/fonts/lato/lato-v16-latin-regular.svg#Lato") format("svg"); /* Legacy iOS */
// }
// /* lato-italic - latin */
// @font-face {
//     font-display: swap;
//     font-family: "Lato";
//     font-style: italic;
//     font-weight: 400;
//     src: url("/fonts/lato/lato-v16-latin-italic.eot"); /* IE9 Compat Modes */
//     src: local("Lato Italic"), local("Lato-Italic"),
//         url("/fonts/lato/lato-v16-latin-italic.eot?#iefix")
//             format("embedded-opentype"),
//         /* IE6-IE8 */ url("/fonts/lato/lato-v16-latin-italic.woff2")
//             format("woff2"),
//         /* Super Modern Browsers */
//             url("/fonts/lato/lato-v16-latin-italic.woff") format("woff"),
//         /* Modern Browsers */ url("/fonts/lato/lato-v16-latin-italic.ttf")
//             format("truetype"),
//         /* Safari, Android, iOS */
//             url("/fonts/lato/lato-v16-latin-italic.svg#Lato") format("svg"); /* Legacy iOS */
// }
// /* lato-700italic - latin */
// @font-face {
//     font-display: swap;
//     font-family: "Lato";
//     font-style: italic;
//     font-weight: 700;
//     src: url("/fonts/lato/lato-v16-latin-700italic.eot"); /* IE9 Compat Modes */
//     src: local("Lato Bold Italic"), local("Lato-BoldItalic"),
//         url("/fonts/lato/lato-v16-latin-700italic.eot?#iefix")
//             format("embedded-opentype"),
//         /* IE6-IE8 */ url("/fonts/lato/lato-v16-latin-700italic.woff2")
//             format("woff2"),
//         /* Super Modern Browsers */
//             url("/fonts/lato/lato-v16-latin-700italic.woff") format("woff"),
//         /* Modern Browsers */ url("/fonts/lato/lato-v16-latin-700italic.ttf")
//             format("truetype"),
//         /* Safari, Android, iOS */
//             url("/fonts/lato/lato-v16-latin-700italic.svg#Lato") format("svg"); /* Legacy iOS */
// }
// /* lato-700 - latin */
// @font-face {
//     font-display: swap;
//     font-family: "Lato";
//     font-style: normal;
//     font-weight: 700;
//     src: url("/fonts/lato/lato-v16-latin-700.eot"); /* IE9 Compat Modes */
//     src: local("Lato Bold"), local("Lato-Bold"),
//         url("/fonts/lato/lato-v16-latin-700.eot?#iefix")
//             format("embedded-opentype"),
//         /* IE6-IE8 */ url("/fonts/lato/lato-v16-latin-700.woff2")
//             format("woff2"),
//         /* Super Modern Browsers */ url("/fonts/lato/lato-v16-latin-700.woff")
//             format("woff"),
//         /* Modern Browsers */ url("/fonts/lato/lato-v16-latin-700.ttf")
//             format("truetype"),
//         /* Safari, Android, iOS */
//             url("/fonts/lato/lato-v16-latin-700.svg#Lato") format("svg"); /* Legacy iOS */
// }
// /* lato-900 - latin */
// @font-face {
//     font-display: swap;
//     font-family: "Lato";
//     font-style: normal;
//     font-weight: 900;
//     src: url("/fonts/lato/lato-v16-latin-900.eot"); /* IE9 Compat Modes */
//     src: local("Lato Black"), local("Lato-Black"),
//         url("/fonts/lato/lato-v16-latin-900.eot?#iefix")
//             format("embedded-opentype"),
//         /* IE6-IE8 */ url("/fonts/lato/lato-v16-latin-900.woff2")
//             format("woff2"),
//         /* Super Modern Browsers */ url("/fonts/lato/lato-v16-latin-900.woff")
//             format("woff"),
//         /* Modern Browsers */ url("/fonts/lato/lato-v16-latin-900.ttf")
//             format("truetype"),
//         /* Safari, Android, iOS */
//             url("/fonts/lato/lato-v16-latin-900.svg#Lato") format("svg"); /* Legacy iOS */
// }
// /* lato-900italic - latin */
// @font-face {
//     font-display: swap;
//     font-family: "Lato";
//     font-style: italic;
//     font-weight: 900;
//     src: url("/fonts/lato/lato-v16-latin-900italic.eot"); /* IE9 Compat Modes */
//     src: local("Lato Black Italic"), local("Lato-BlackItalic"),
//         url("/fonts/lato/lato-v16-latin-900italic.eot?#iefix")
//             format("embedded-opentype"),
//         /* IE6-IE8 */ url("/fonts/lato/lato-v16-latin-900italic.woff2")
//             format("woff2"),
//         /* Super Modern Browsers */
//             url("/fonts/lato/lato-v16-latin-900italic.woff") format("woff"),
//         /* Modern Browsers */ url("/fonts/lato/lato-v16-latin-900italic.ttf")
//             format("truetype"),
//         /* Safari, Android, iOS */
//             url("/fonts/lato/lato-v16-latin-900italic.svg#Lato") format("svg"); /* Legacy iOS */
// }

//end fonts

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";


//Typography - start
h1 {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 101px;
    line-height: 121px;
    letter-spacing: -0.015em;
    margin: 0px;
}
h1 strong {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 101px;
    line-height: 121px;
    letter-spacing: -0.015em;
    margin: 0px;
}
h2 {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 63px;
    line-height: 76px;
    letter-spacing: -0.005em;
    margin: 0px;
}
h2 strong {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 63px;
    line-height: 76px;
    letter-spacing: -0.005em;
    margin: 0px;
}
h3 {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 60px;
    margin: 0px;
}
h3 strong {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
    margin: 0px;
}
h4 {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: 0.0025em;
    margin: 0px;
}
h4 strong {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: 0.0025em;
    margin: 0px;
}
h5 {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.0015em;
    margin: 0px;
}
h5 strong {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.0015em;
    margin: 0px;
}
h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: 0.0015em;
    margin: 0px;
}
h6 strong {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: 0.0015em;
    margin: 0px;
}
.sub1 {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.0015em;
}
.sub1 strong {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.0015em;
}
.sub2 {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.001em;
}
.sub2 strong {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.001em;
}
.body1 {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 0.005em;
}
.body1 strong {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 0.005em;
}
.body2 {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.0025em;
}
.body2 strong {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.0025em;
}
.button {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
}
.button strong {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
}
.caption {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.004em;
}
.caption strong {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.004em;
}
.overline {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.015em;
    text-transform: uppercase;
}
.overline strong {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.015em;
    text-transform: uppercase;
}
//Typography - end
.content {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 0.009em;
    text-align: justify;
}

//Spacing
/*
.px-4 {
    padding-left: 40px !important;
    padding-right: 40px !important;
    @include media-breakpoint-down(xs) {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
.px-5 {
    padding-left: 84px !important;
    padding-right: 84px !important;
    @include media-breakpoint-down(xs) {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

.pr-2 {
    padding-right: 14px;
}

.pr-4 {
    padding-right: 42px !important;
}
.pr-5 {
    padding-right: 84px !important;
}
.pl-4 {
    padding-left: 42px !important;
}
.pt-4 {
    padding-top: 40px !important;
}
.pb-4 {
    padding-bottom: 40px !important;
}
.pb-2 {
    padding-bottom: 16px !important;
}
.pb-1 {
    padding-bottom: 12px !important;
}

.ml-2 {
    margin-left: 9px !important;
}
.ml-3 {
    margin-left: 24px !important;
}
.ml-4 {
    margin-left: 30px;
    @media (max-width: 396px) {
        margin-left: 15px !important;
    }
    @media (max-width: 390px) {
        margin-left: 5px !important;
    }
    @media (max-width: 378px) {
        margin-left: 0px !important;
    }
}

.mr-4 {
    margin-right: 48px !important;
}

.mt-4 {
    margin-top: 48px !important;
}
.mb-2 {
    margin-bottom: 8px !important;
}
.mb-3 {
    margin-bottom: 28px !important;
}
.mb-4 {
    margin-bottom: 48px !important;
}

*/
#app {
    overflow-x: hidden;
    overflow-y: hidden;
}

main {
    margin-top: 160px !important;
    @media (max-width: 393px) {
        margin-top: 140px !important;
    }
    @media (max-width: 373px) {
        margin-top: 120px !important;
    }
    @media (max-width: 353px) {
        margin-top: 100px !important;
    }
    @media (max-width: 333px) {
        margin-top: 70px !important;
    }
}
.container {
    max-width: 100% !important;
    width: 100% !important;
}

#search {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);

    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    -webkit-transform: translate(0px, -100%) scale(0, 0);
    -moz-transform: translate(0px, -100%) scale(0, 0);
    -o-transform: translate(0px, -100%) scale(0, 0);
    -ms-transform: translate(0px, -100%) scale(0, 0);
    transform: translate(0px, -100%) scale(0, 0);

    opacity: 0;
    z-index: 2000;
}
.material-icons {
    height: 27px;
    width: 27px;
}

//Navbar - start
.navbar {
    border-bottom: 1px solid $gray-200;
    box-shadow: none !important;
}
.nav-right {
    @include media-breakpoint-only(lg) {
        margin-left: -100px !important;
    }
}
.nav-link {
    align-self: center;
    padding: 0 !important;
    color: $gray-900;
}
.nav-link h6 {
    margin-bottom: 0px;
}

.navbar-brand {
    width: 160px;
    height: 160px;
    @media (max-width: 393px) {
        width: 140px !important;
        height: 140px !important;
    }
    @media (max-width: 373px) {
        width: 120px !important;
        height: 120px !important;
    }
    @media (max-width: 353px) {
        width: 100px !important;
        height: 100px !important;
    }
    @media (max-width: 333px) {
        width: 70px !important;
        height: 70px !important;
    }
}
.navbar-brand img {
    height: 160px !important;
    @media (max-width: 393px) {
        height: 140px !important;
    }
    @media (max-width: 373px) {
        height: 120px !important;
    }
    @media (max-width: 353px) {
        height: 100px !important;
    }
    @media (max-width: 333px) {
        height: 70px !important;
    }
}
.navbar-collapse {
    height: 160px;
}
.navbar-nav {
    //margin-bottom: 36px;
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    text-decoration: underline;
}
.navbar-collapse {
    height: auto;
}
#main-nav {
    z-index: 2001;
}
#lblCartCount {
    font-size: 12px;
    background: #636b6f;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-left: -10px;
}
#sidebar-wrapper {
    position: fixed;
    height: 100%;
    width: 0px;
    margin-top: 160px;
    @media (max-width: 393px) {
        margin-top: 140px !important;
    }
    @media (max-width: 373px) {
        margin-top: 120px !important;
    }
    @media (max-width: 353px) {
        margin-top: 100px !important;
    }
    @media (max-width: 333px) {
        margin-top: 70px !important;
    }
    //margin-left: -100px;
    z-index: 2000;
    top: 0px;
    left: 0px;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    opacity: 1;
    //padding: 40px;
    //transition-property: width;
    box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.2);
}
#sidebar-wrapper.show {
    width: 290px;
    padding: 40px;
    //margin-left: 0px;
}
#sidebar-wrapper.collapsing {
    //height: auto !important;
    //left: 50%;
    transition: all 0.2s ease;
}

#sidebar-wrapper h6 {
    margin-bottom: 20px !important;
}
#sidebar-wrapper i {
    margin-top: -20px;
    margin-right: -20px;
}
.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(26, 25, 25, 0.38);

    overflow-x: hidden;
    //transition: 0.5s;
}

#search.open {
    -webkit-transform: translate(0px, 0px) scale(1, 1);
    -moz-transform: translate(0px, 0px) scale(1, 1);
    -o-transform: translate(0px, 0px) scale(1, 1);
    -ms-transform: translate(0px, 0px) scale(1, 1);
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
}

#search input[type="search"] {
    position: absolute;
    top: 50%;
    width: 100%;
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0);
    
    font-weight: 300;
    text-align: center;
    border: 0px;
    margin: 0px auto;
    margin-top: -51px;
    //padding-left: 30px;
    padding-right: 30px;
    outline: none;
    @include media-breakpoint-up(lg) {
        font-size: 60px;
    }
    @include media-breakpoint-down(md) {
        font-size: 30px;
    }
}
#search .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 61px;
    margin-left: -45px;
}
#search .close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: #fff;
    background-color: #428bca;
    border-color: #357ebd;
    opacity: 1;
    padding: 10px 17px;
    font-size: 27px;
}
//Navbar - end

//footer - start
.footer .container .row {
    padding: 40px 40px 45px;
    bottom: 0;
    width: 100%;
    //height: 350px;
    background-color: $footer-bg;
}
.footer .container {
    position: relative;
}

.social_icon {
    height: 32px;
    width: 32px;
}
.footer-address {
    //width: 220px;
}
.footer-aboutus {
    z-index: 1;
}
.footer-watermark img {
    position: absolute;
    bottom: 0; /* or whatever */
    right: 0; /* or whatever, position according to taste */
    opacity: 0.5; /* Firefox, Chrome, Safari, Opera, IE >= 9 (preview) */
    width: 273px;
    filter: alpha(opacity=50);
    z-index: 0;
}

//footer - end

//product component - start
.product-link {
    color: $gray-900;
}
//product component - end

//product component - start
.card-columns.topproducts {
    @include media-breakpoint-only(xm) {
        column-count: 1;
    }
    @include media-breakpoint-only(sm) {
        column-count: 2;
    }
    @include media-breakpoint-only(md) {
        column-count: 3;
    }
    @include media-breakpoint-only(lg) {
        column-count: 3;
    }
    @include media-breakpoint-only(xl) {
        column-count: 4;
    }
}

.card-columns.posts {
    @include media-breakpoint-only(xm) {
        column-count: 1;
    }
    @include media-breakpoint-only(sm) {
        column-count: 2;
    }
    @include media-breakpoint-only(md) {
        column-count: 3;
    }
    @include media-breakpoint-only(lg) {
        column-count: 3;
    }
    @include media-breakpoint-only(xl) {
        column-count: 3;
    }
}

.products .card {
    box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.2);
    border-radius: 10px;
    position: initial;
    max-width: 300px;
    max-height: 422px;
    min-width: 300px;
    min-height: 422px;
}
.products a {
    text-decoration-line: none;
}
.products .card-img-top {
    border-bottom: 1px solid $gray-200;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}
//product component - end

//cart and checkout - start
.coupon {
    border: 1px solid $gray-900;
    box-sizing: border-box;
    border-radius: 8px;
}
.coupon input {
    outline: none;
    border: none;
    background-color: transparent;
}
.coupon .input-group-append {
    margin: -1px;
}
.coupon input:focus {
    outline: none;
    border: none;
    background-color: transparent;
}
.order-summary {
    box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.2);
    border-radius: 8px;
    padding: 24px;
    margin-top: 32px;
}
.order-summary .sub1 {
    color: $gray-800;
}
.button {
    padding: 14.5px 24px 14.5px;
}
#promo {
    box-shadow: none !important;
    text-transform: uppercase;
}
//cart and checkout - end

.links > * {
    color: #636b6f;
    padding: 0 25px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.1rem;
    text-decoration: none;
    text-transform: uppercase;
}
.badge {
    padding-left: 9px;
    padding-right: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
    background-color: #c67605;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $dark;
    border-color: $dark;
}

.form-control:focus {
    border-color: $dark;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.25);
}
.custom-checkbox .custom-control-input:checked:focus {
    background-color: $dark;
    border-color: $dark;
}

// #suggestBoxElement {
//     border: 1px solid #8fabff;
//     visibility: hidden;
//     text-align: left;
//     white-space: nowrap;
//     background-color: #eeeeee;
// }
// .suggestions {
//     font-size: 14;
//     background-color: #eeeeee;
// }
// .suggestionMouseOver {
//     font-size: 14;
//     background: #3333ff;
//     color: white;
// }
// /*
// *
// * ==========================================
// * CUSTOM UTIL CLASSES
// * ==========================================
// *
// */
// /* Horizontal line */
// .collapsible-link::before {
//     content: "";
//     width: 14px;
//     height: 2px;
//     background: #333;
//     position: absolute;
//     top: calc(50% - 1px);
//     right: 1rem;
//     display: block;
//     transition: all 0.3s;
// }

// /* Vertical line */
// .collapsible-link::after {
//     content: "";
//     width: 2px;
//     height: 14px;
//     background: #333;
//     position: absolute;
//     top: calc(50% - 7px);
//     right: calc(1rem + 6px);
//     display: block;
//     transition: all 0.3s;
// }

// .collapsible-link[aria-expanded="true"]::after {
//     transform: rotate(90deg) translateX(-1px);
// }

// .collapsible-link[aria-expanded="true"]::before {
//     transform: rotate(180deg);
// }

// .carousel-thumbnails .carousel-indicators {
//     position: static;
//     left: initial;
//     width: initial;
//     margin-right: initial;
//     margin-left: initial;
//     overflow-x: auto;
//     white-space: nowrap;
// }
// .carousel-thumbnails .carousel-indicators > li {
//     width: initial;
//     height: initial;
//     text-indent: initial;
// }

// .carousel .carousel-indicators li {
//     cursor: pointer;
//     border-radius: 50%;
// }

// .carousel-indicators .active {
//     opacity: 1;
// }

// .dropdown-submenu {
//     position: relative;
// }

// .dropdown-submenu > a:after {
//     content: "\f0da";
//     float: right;
//     border: none;
//     font-family: "Font Awesome 5 Free";
//     font-weight: 900;
// }

// .dropdown-submenu > .dropdown-menu {
//     top: 0;
//     left: 100%;
//     margin-top: 0px;
//     margin-left: 0px;
// }
